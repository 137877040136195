import React, { useState,useCallback } from "react";
import Logo from "../../assets/logo.svg";
import "./Header.scss";
import useCurrentWidth from "../Hooks/useCurrentWidth.jsx";
import { Drawer, Grid } from "antd";
import HeaderRight from "../HeaderRight/HeaderRight.jsx";
import { BreadCrumb, ResLogo } from "../SvgIcons/SvgIcons";
import {Link,useLocation,useNavigate} from "react-router-dom"
import darkLogo from "../../assets/darkLogo.svg"
import ApplicantHeader from "../ApplicantHeader/ApplicantHeader.jsx";

function Header() {
  const { useBreakpoint } = Grid;
  const { xl, lg, sm, md } = useBreakpoint();
  const [open, setOpen] = useState(false);
  const width = useCurrentWidth();
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > (width > 991 ? 0 : 100)) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  window.addEventListener("scroll", handleScroll);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
   const [scrollTarget, setScrollTarget] = useState(null); 
  const navigate = useNavigate();
  const location = useLocation();
  const handleScrollItem = useCallback((event, targetId) => {
    event.preventDefault();
    if (location.pathname === "/") {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      setScrollTarget(targetId);
      navigate("/");
    }
  }, [location.pathname, navigate]);
  return (
    <div className={`headerItem ${isScrolled ? "scroll" : ""}`} id="home">
        {/* <ApplicantHeader /> */}
      <div className="container">
        <div className="headerItem_Main">
          <div className="headerItem_Main__headerlogo me-auto">
            <Link className="lightLogo" to="/"onClick={(e) => handleScrollItem(e, 'superApp')}>
              {" "}
              <img src={Logo} alt="Logo" />
            </Link>
            <Link className="darkLogo" to="/"onClick={(e) => handleScrollItem(e, 'superApp')}>
              {" "}
              <img src={darkLogo} alt="Logo" />
            </Link>
          </div>
          <div className="headerItem_Main__rightSec">
            {xl && <HeaderRight />}

            {!xl && (
              <>
                <div className="menuToggle" onClick={showDrawer}>
                  <BreadCrumb />
                </div>

                <Drawer
                  placement="right"
                  onClose={onClose}
                  open={open}
                  width={sm ? "378px" : "300px"}
                  className="headerItem_Main__rightSec"
                >
                  <HeaderRight onCloseDrawer={onClose} />
                </Drawer>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
