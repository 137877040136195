import React from "react";
import ContactUs from "../pages/ContactUs/ContactUs.jsx";
import Home from "../pages/Home/Home.jsx";
import Path from "./Constant/RoutePath.jsx";
import ArchitectingNext from "../pages/ArchitectingNext/ArchitectingNext.jsx";
import OurCertificate from "../pages/Home/HomeComponets/OurCertificate/OurCertificate.jsx";
export default function PAGES() {
  return {
    PUBLIC_PAGES: [
      { path: Path.HOME, element: <Home /> },
      { path: Path.CONTACTUS, element: <ContactUs /> },
      { path: Path.ARCHITECTING, element: <ArchitectingNext /> },
      { path: Path.CERTIFICATES, element: <OurCertificate /> },
    ],
  };
}
