import React from "react";
import "./Roadmap.scss";
function Roadmap() {
  return (
    <div className="roadmap container" id="roadmap">
      <div className="roadmap_inner">
        <video
          src={"https://d2j1mnvck8397w.cloudfront.net/roadmap.mp4"}
          muted
          autoPlay
          loop
          playsInline
          controls={false}
        />
        <div className="roadmap_inner_data">
          <h2>Roadmap</h2>
          <p className="secureText">
            Triskel innovates global finance by expanding DeFi Access
            and<br/>elevating user experience.
          </p>
          <div className="roadmapTriskel">
            <div className="triskelWallet">
              <div className="triskelWallet_inner">
                <span>01</span>
                <div>
                  <h4>Triskel Wallet</h4>
                  <p>
                    Secure Multi-Chain Solution for Fiat-Compatible Token
                    Management.
                  </p>
                </div>
              </div>
              <div className="triskelWallet_inner">
                <span>02</span>
                <div>
                  <h4>OTC Platform</h4>
                  <p>
                    Our partnership with Liminal safeguards assets and manages
                    OTC transactions, strengthening financial stability.
                  </p>
                </div>
              </div>
              <div className="triskelWallet_inner">
                <span>03</span>
                <div>
                  <h4>Hybrid Crypto Cards</h4>
                  <p>
                    Triskel elevates global spending with backed crypto prepaid
                    cards.
                  </p>
                </div>
              </div>
            </div>
            <div className="triskelWallet">
              <div className="triskelWallet_inner">
                <span>04</span>
                <div>
                  <h4>STO Platform</h4>
                  <p>
                    Triskel introduces a secure STO platform that allows
                    access for a wide range of real-world assets in real-time.
                  </p>
                </div>
              </div>
              <div className="triskelWallet_inner">
                <span>05</span>
                <div>
                  <h4>Payment Gateway</h4>
                  <p>
                    Triskel enables easy payment solutions for quick and
                    convenient transactions.
                  </p>
                </div>
              </div>
              <div className="triskelWallet_inner">
                <span>06</span>
                <div>
                  <h4>IBAN</h4>
                  <p>
                    Virtual International banking account that facilitates
                    Cross-border transactions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
