import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import CustomDropdown from "../CustomDropdown/CustomDropdown.jsx";
import { ButtonCustom } from "../ButtonCustom/ButtonCustom.jsx";
import { Link } from "react-router-dom";
import { SmallPlay, SmallApp } from "../SvgIcons/SvgIcons.jsx";
import { RightOutlined } from "@ant-design/icons";
import { Grid } from "antd";
import TriskelWhitepaper from "../../assets/Pdf/TriskelWhitepaper.pdf";

function HeaderRight({ onCloseDrawer }) {
  const [scrollTarget, setScrollTarget] = useState(null);
  const { useBreakpoint } = Grid;
  const { xl } = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const handleScroll = useCallback(
    (event, targetId) => {
      event.preventDefault();
      if (location.pathname === "/") {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
          targetElement.classList.add("scroll-padding");
        } else targetElement.classList.remove("scroll-padding");
      } else {
        setScrollTarget(targetId);
        navigate("/");
      }
    },
    [location.pathname, navigate]
  );
  // const handleClick = useCallback(() => {
  //   setScrollTarget("contactUsSection");
  //   navigate("/contactUs", { replace: true });
  //   window.scrollTo(0, 0); // Scroll to the top of the screen
  // }, [navigate]);
  const handleDownload = async () => {
    try {
      // Fetch the PDF file as a blob
      const response = await fetch(TriskelWhitepaper, { mode: "cors" });
      if (!response.ok) throw new Error("Network response was not ok");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a link element to download the blob
      const link = document.createElement("a");
      link.href = url;
      link.download = "Triskel_Whitepaper.pdf";
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };
  useEffect(() => {
    if (scrollTarget && location.pathname === "/") {
      const targetElement = document.getElementById(scrollTarget);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
      setScrollTarget(null);
    }
  }, [scrollTarget, location.pathname]);
  const menuItems = [
    {
      key: "1",
      label: (
        <Link
          className="playApple"
          to="https://play.google.com/store/search?q=triskel+wallet&c=apps"
          target="_blank"
        >
          <SmallPlay /> Play Store
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          className="playApple"
          to="https://apps.apple.com/in/app/triskel-wallet/id6449494626"
          target="_blank"
        >
          <SmallApp /> Apple Store
        </Link>
      ),
    },
  ];
  const franchiseeItems = [
    {
      key: "1",
      label: (
        <div>
          <Link
            // className="playApple"
            to="/franchisee-partner"
            target="_blank"
          >
            <span>Franchisee: Login to the portal</span>
            <RightOutlined />
          </Link>
          <hr />
          <p>
            New Member - Explore app and earn
            <br /> rewards, get access to franchisee portal!
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "superApp");
          !xl && onCloseDrawer();
        }}
        // onClick={onCloseDrawer}
      >
        Home
      </NavLink>
      {/* <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "whyTriskel");
          !xl && onCloseDrawer();
        }}
      >
        Why Triskel
      </NavLink> */}
      <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "feature");
          !xl && onCloseDrawer();
        }}
      >
        Wallet Features
      </NavLink>

      <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "cryptoCard");
          !xl && onCloseDrawer();
        }}
      >
        Crypto Card
      </NavLink>
      <NavLink
        to={TriskelWhitepaper}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          !xl && onCloseDrawer();
          handleDownload();
        }}
      >
        Whitepaper
      </NavLink>

      <a href="/blogs">Blogs</a>

      <NavLink
        to="/franchisee-partner"
        onClick={(e) => {
          !xl && onCloseDrawer();
        }}
      >
        Franchisee Login
      </NavLink>
      {/* <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "usecases");
          !xl && onCloseDrawer();
        }}
      >
        Use Cases
      </NavLink> */}
      {/* <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "token");
          !xl && onCloseDrawer();
        }}
      >
        Triskel Token
      </NavLink> */}
      {/* <NavLink
        to="/"
        onClick={(e) => {
          handleScroll(e, "roadmap");
          !xl && onCloseDrawer();
        }}
      >
        Roadmap
      </NavLink> */}
      {/* <NavLink
        to="https://www.en.triskel-group.com/Whitepaper-Triskel-2024-EN.pdf"
        target="_blank"
      >
        Whitepaper
      </NavLink> */}
      {/* <NavLink
        to="/contactUs"
        onClick={(e) => {
          !xl && onCloseDrawer();
        }}
      >
        Contact Us
      </NavLink> */}
      <div className="navButtons">
        {/* <CustomDropdown items={menuItems} title="Download" /> */}
        {/* <CustomDropdown
          overlayClassName="Franchisee ant-dropdown-placement-bottomRight"
          items={franchiseeItems}
          title="Franchisee Login"
          placement="bottomRight"
        /> */}
        {/* <ButtonCustom
        style={{maxWidth:'175px'}}
          greenborder
          label="Contact Us"
          onClick={handleClick}
          btnBorder={true}
        /> */}
      </div>
    </>
  );
}

export default HeaderRight;
