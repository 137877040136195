import React from "react";
import "./WhyTriskel.scss";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
function WhyTriskel() {
  const { whytriskel } = StoreImages;
  const cardData = [
    {
      title: "User Empowerment",
      description:
        "Users retain absolute control and ownership of their digital assets, thus achieving financial democracy.",
      number: "01",
    },
    {
      title: "Security and Privacy",
      description:
        "Fully secure transactions and zero storage of the associated data - all backed by blockchain technology.",
      number: "02",
    },
    {
      title: "Streamlined Trade",
      description:
        "Simple and rapid processing and smooth conversions between fiat and cryptocurrencies.",
      number: "03",
    },
    {
      title: "Hybrid Ecosystem",
      description:
        "Merges decentralized technologies and centralized banking practices for the creation of a dynamic financial landscape.",
      number: "04",
    },
    {
      title: "Global Acceptance",
      description:
        "Supporting worldwide transactions in digital and physical marketplaces with Crypto Visa Card Network.",
      number: "05",
    },
  ];

  return (
    <div className="container">
      <div className="whyTriskel" id="whyTriskel">
        <div className="whyTriskel_left">
          <img src={whytriskel} alt="imgs" />
        </div>
        <div className="whyTriskel_right">
          <h2>Why Triskel?</h2>
          <p>
          Triskel drives web3 adoption and builds a DeFi economy, empowering billions with access to financial opportunities and services

          </p>
          <div className="whyTriskel_right_cards">
            {cardData.map((card, index) => (
              <div className="cardsData" key={index}>
                <h4>{card.title}</h4>
                <p>{card.description}</p>
                <span>{card.number}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyTriskel;
