import React from "react";
import "./SuperApp.scss";
import { Link } from "react-router-dom";
import { GreenApp, GreenPlay } from "../../../../Common/SvgIcons/SvgIcons";
import { Carousel } from "antd";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
function SuperApp() {
  const {
    craouselthird,
    craouselfirst,
    craouselsecond,
    craouselfourth,
    craouselFifth,
    frame,
  } = StoreImages;
  return (
    <div className="superApp" id="superApp">
      <video
        src={"https://d2j1mnvck8397w.cloudfront.net/superapp.mp4"}
        muted
        autoPlay
        loop
        playsInline
        controls={false}
      />
      <h1>
        Ultimate Web3 Super App
      </h1>
      <p>The all in one Non-Custodial Wallet</p>

      <div className="superApp_mobile">
        <div className="superApp_mobile_data">
          <div className="frame-container">
            <img src={frame} alt="imgs" className="frame-image" />
            <div className="carousel-overlay">
              <Carousel autoplay>
                <div>
                  <img src={craouselfirst} alt="craousel" />
                </div>{" "}
                <div>
                  <img src={craouselsecond} alt="craousel" />
                </div>{" "}
                <div>
                  <img src={craouselthird} alt="craousel" />
                </div>{" "}
                <div>
                  <img src={craouselfourth} alt="craousel" />
                </div>
                <div>
                  <img src={craouselFifth} alt="craousel" />
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <div className="bannerbuttons">
        <Link to="https://play.google.com/store/search?q=triskel+wallet&c=apps">
          <GreenPlay />
        </Link>
        <Link to="https://apps.apple.com/in/app/triskel-wallet/id6449494626">
          <GreenApp />
        </Link>
      </div>
    </div>
  );
}

export default SuperApp;
