import React, { useState, useEffect } from "react";
import "./OurCertificate.scss";
import CustomModal from "../../../../Common/CustomModal/CustomModal";
import { Button } from "antd";
import { StoreImages } from "../../../../assets/StoreAsset/StoreImages";
import { Link } from "react-router-dom";
import PageTitle from "../../../../Common/PageTitle/PageTitle";

function OurCertificate() {
  const [visibleModalId, setVisibleModalId] = useState(null);

  const showModal = (id) => {
    setVisibleModalId(id);
  };

  const handleOk = () => {
    console.log("Clicked OK");
    setVisibleModalId(null);
  };

  const handleCancel = () => {
    console.log("Clicked Cancel");
    setVisibleModalId(null);
  };

  useEffect(() => {
    document.body.classList.add("contactUsBg");
    return () => {
      document.body.classList.remove("contactUsBg");
    };
  }, []);

  const {
    certificatedubai,
    certificateWyoming,
    certificateisland,
    certificatepanama,
    certificatesaint,
    LicenseCzech,
  } = StoreImages;

  const certificates = [
    {
      id: 1,
      state: "Triskel Holding Dubai",
      view: "View",
      // content: "Content for Dubai certificate",
      description: (
        <img src={certificatedubai} alt="imgs" className="modalImg" />
      ),
    },

    {
      id: 2,
      state: "Triskel Crypto Fiat License Czech Republic",
      // united: "(United States)",
      view: "View",
      // content: "Content for Saint Vincent and the Grenadines certificate",
      description: <img src={LicenseCzech} alt="imgs" className="modalImg" />,
    },
    {
      id: 3,
      state: "Triskel Technology Panama",
      // united: "(United States)",
      view: "View",
      // content:
      //   "Content for Triskel Technologies International S.A. certificate",
      description: (
        <img src={certificatepanama} alt="imgs" className="modalImg" />
      ),
    },

    {
      id: 4,
      state: "Triskel Commercial Wyoming",
      // united: "(United States)",
      view: "View",
      // content: "Content for State of Wyoming certificate",
      description: (
        <img src={certificateWyoming} alt="imgs" className="modalImg" />
      ),
    },

    {
      id: 5,
      state: "Triskel DAO Fund Marshall Islands",
      // united: "(United States)",
      view: "View",
      // content: "Content for Republic of the Marshall Islands certificate",
      description: (
        <img src={certificateisland} alt="imgs" className="modalImg" />
      ),
    },

    {
      id: 6,
      state: "Triskel Proprietary Broker SVG",
      // united: "(United States)",
      view: "View",
      content: "Content for State of Wyoming certificate",
      description: (
        <img src={certificatesaint} alt="img Empty" className="modalImg" />
      ),
    },
    {
      id: 7,
      state: "Triskel MSB Canada (In Process)",
      // united: "(United States)",
      // view: "View",
      // content: "Content for Saint Vincent and the Grenadines certificate",
      description: (
        <img src={certificatesaint} alt="imgs" className="modalImg" />
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Certificates" />
      <div className="container certificates">
        <h2>Our Certificates</h2>
        <div className="certificates_card">
          {certificates.map((certificate) => (
            <div className="certificates_card_inner" key={certificate.id}>
              <p>{certificate.state}</p>
              <span> {certificate.united}</span>
              <span
                className="greenright"
                onClick={() => showModal(certificate.id)}
              >
                {certificate.view}
              </span>
              <CustomModal
                visible={visibleModalId === certificate.id}
                title={false}
                content={certificate.description}
                onOk={handleOk}
                onCancel={handleCancel}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default OurCertificate;
