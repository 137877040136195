import React from "react";
import blackapp from "../../../../assets/blackapp.svg";
import downloadphone from "../../../../assets/downloadphone.png";
import blackpay from "../../../../assets/blackgpay.svg";
import { Link } from "react-router-dom";
import "./Download.scss";
function DownloadSuperApp() {
  return (
    <div className="downsuperapp container">
      <div className="downsuperapp_left">
        <h2>
          Triskel Wallet.
          <br /> The Ultimate Web3 Super&nbsp;App
        </h2>
        <div className="downsuperapp_left_buttons">
          <Link to="https://play.google.com/store/search?q=triskel+wallet&c=apps">
            <img src={blackpay} alt="imgs" />
          </Link>
          <Link to="https://apps.apple.com/in/app/triskel-wallet/id6449494626">
            <img src={blackapp} alt="imgs" />
          </Link>
        </div>
      </div>
      <div className="downsuperapp_right">
        <img src={downloadphone} alt="imgs" />
      </div>
    </div>
  );
}

export default DownloadSuperApp;
